import { useState, useCallback } from 'react'
import CryptoJS from 'crypto-js'

function _scrollToID(id, speed){
	var targetOffset = window.$(id).offset().top;
  console.log(id, window.$(id), targetOffset)
	window.$('html, body').animate({scrollTop: targetOffset}, speed);
}

export function scrollToIdAction(sectionID, speed = 750) {
  return (event) => {
    event.preventDefault();
    _scrollToID('#' + sectionID, speed);
  }
}

export function scrollToTopAction() {
  return (event) => {
    event.preventDefault();
    window.$('html, body').animate({scrollTop:0}, 'slow'); 		  
  }
}


export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return(item ? JSON.parse(item) : initialValue)
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return(initialValue);
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value) => {
      try {
        // Save state
        setStoredValue(oldValue => {
          // Allow value to be a function so we have same API as useState
          const valueToStore = value instanceof Function ? value(oldValue) : value;

          // Save to local storage
          window.localStorage.setItem(key, JSON.stringify(valueToStore));

          // set the new state
          return valueToStore;
        });
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [key],
  );

  return [storedValue, setValue];
}



function readChunked(file, chunkCallback, endCallback) {
  var fileSize   = file.size;
  var chunkSize  = 4 * 1024 * 1024; // 4MB
  var offset     = 0;
  var reader = new FileReader();
  reader.onload = function() {
    if (reader.error) {
      endCallback(reader.error || {});
      return;
    }
    offset += reader.result.length;
    chunkCallback(reader.result, offset, fileSize); 
    if (offset >= fileSize) {
      endCallback(null);
      return;
    }
    readNext();
  };
  reader.onerror = function(err) {
    endCallback(err || {});
  };
  function readNext() {
    var fileSlice = file.slice(offset, offset + chunkSize);
    reader.readAsBinaryString(fileSlice);
  }
  readNext();
}

export function getMD5(blob, cbProgress) {
  return new Promise((resolve, reject) => {
    var md5 = CryptoJS.algo.MD5.create();
    readChunked(blob, (chunk, offs, total) => {
      md5.update(CryptoJS.enc.Latin1.parse(chunk));
      if (cbProgress) {
        cbProgress(offs / total);
      }
    }, err => {
      if (err) {
        reject(err);
      } else {
        // TODO: Handle errors
        var hash = md5.finalize();
        var hashHex = hash.toString(CryptoJS.enc.Hex);
        resolve(hashHex);
      }
    });
  });
}