import React, { useContext, useMemo } from 'react';
import axios from 'axios';
import { useLocalStorage } from './utils'
import { navigate } from "gatsby-plugin-intl"

const NetworkContext = React.createContext({});
const UserContext = React.createContext(null);

export function useNetwork() {
  return useContext(NetworkContext);
}

export function useCurrentAuth() {
  return useContext(UserContext);
}

export const NetworkProvider = ({ children }) => {
  const [currentAuth, setCurrentAuth] = useLocalStorage('Auth', null);

  // create the context data for the api
  const ctx = useMemo(() => {
    // the axios interceptor might call this, so we have to define it before
    const logout = () => { 
      setCurrentAuth(null); 
      navigate("/login/")
    };

    // axios interceptor initialization
    const instance = axios.create({
      baseURL: '/api',
      headers: {
        Accept: 'application/json',
      },
    });

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      },
    );

    instance.interceptors.request.use((config) => {
      if (currentAuth) {
        config.headers.Authorization = `${currentAuth.token_type} ${currentAuth.token}`;
      }
      return config;
    });

    // generate the registry
    const get = async (url) => (await instance.get(url)).data;
    const post = async (url, data) => (await instance.post(url, data)).data;
    const destroy = async (url) => (await instance.delete(url)).data;

    const login = async (email, password) => {
      setCurrentAuth(await post('/login', { email, password }));
    };

    return {
      /**
       * Session methods
       */
      login,
      logout,

      contact: (data) => post("/contact", data),

      /**
       * Load the endpoints
       */
      users: () => get('/users'),
      createUser: (name, email, group, password) => post('/users', {
        name, email, group, password
      }),
      deleteUser: (id) => destroy(`/users/${id}`),

      groups: () => get('/groups'),
      createGroup: (name) => post('/groups', {
        name
      }),
      deleteGroup: (id) => destroy(`/groups/${id}`),



      downloadTypes: () => get('/downloadTypes'),
      createDownloadType: (
        name, 
        description, 
        categorie, 
        escrow, 
        has_windows_service_installer,
        windows_service_installer_program_name,
        windows_service_installer_xml_contents,
      ) => post('/downloadTypes', { 
        name, description, categorie, escrow, has_windows_service_installer, windows_service_installer_program_name, windows_service_installer_xml_contents,
      }),
      getAllowedUserGroupsOnType: (id) => get(`/downloadTypes/${id}/userGroups`),
      allowUserGroupToType: (id, user_group_id) => post(`/downloadTypes/${id}/userGroups`, { user_group_id }),
      deleteDownloadType: (id) => destroy(`/downloadTypes/${id}`),

      downloadWindowsServiceInstaller: (id, version) => {
        const form = document.createElement("form");
        form.setAttribute("method", "post")
        form.setAttribute("target", "_blank")
        form.setAttribute("action", `/api/downloadables/${id}/windowsServiceInstaller/${version}`)
        
        const token = document.createElement("input");
        token.setAttribute('type', 'hidden')
        token.setAttribute('name', 'token')
        token.setAttribute('value', currentAuth.token);

        document.body.appendChild(form)
        form.appendChild(token)
        form.submit()
        document.body.removeChild(form)    
      },

      downloadEntries: (type_id) => get(`downloadTypes/${type_id}/downloadables`),
      performDownload: (id) => {
        const form = document.createElement("form");
        form.setAttribute("method", "post")
        form.setAttribute("target", "_blank")
        form.setAttribute("action", `/api/downloadables/${id}/doDownload`)
        
        const token = document.createElement("input");
        token.setAttribute('type', 'hidden')
        token.setAttribute('name', 'token')
        token.setAttribute('value', currentAuth.token);

        document.body.appendChild(form)
        form.appendChild(token)
        form.submit()
        document.body.removeChild(form)    
      },
      deleteUpload: (id) => destroy(`/download/${id}`),
      upload: async (type_id, name, release_notes, file, hash, progress) => {
        // first we upload the general data
        const formData = new FormData();
        formData.append("download_type_id", type_id);
        formData.append("name", name);
        formData.append("release_notes", release_notes);
        formData.append("hash", hash);
        formData.append("file_name", file.name);
        const upload = await post('/upload', formData);

        // next we create chunks
        let size = 1024 * 1024 * 4;
        let numOfChunks = Math.ceil(file.size / size);
        let chunks = []
        for (let i = 0; i < numOfChunks; i++) {
          chunks.push(file.slice(
            i * size, Math.min(i * size + size, file.size), file.type
          ));
        }

        // now we actually upload the chunks
        for (let i = 0; i < chunks.length; i++) {
          const uploadData = new FormData();
          uploadData.set('is_last', i === (chunks.length - 1));
          uploadData.set('object', chunks[i], `${file.name}.part`);
          await instance.post(`/uploadChunk/${upload.id}`, uploadData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
          })

          progress(Math.min(
            100, 
            Math.ceil(((i + 1) / chunks.length) * 100)
          ));
        }
      },

      generateLicense: async (clientName, licensePayload) => {
        const license = await post('/license/create', { license: licensePayload })
        const blob = new Blob([license]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${clientName}.bel`;
        link.click();    
      },
      readLicense: async (f) => {
        const uploadData = new FormData();
        uploadData.set('license', f);
        return JSON.parse((await instance.post(`/license/open`, uploadData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        })).data)
      }
    }
  }, [setCurrentAuth, currentAuth]);

  return (
    <NetworkContext.Provider value={ctx}>
      <UserContext.Provider value={currentAuth}>
        {children}
      </UserContext.Provider>
    </NetworkContext.Provider>
  );
};
